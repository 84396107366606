/*
 * Globals
 */


/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}


/*
 * Base structure
 */

body {
  text-shadow: 0 .05rem .1rem rgba(0, 183, 255, .5);
  box-shadow: inset 0 0 5rem rgba(0, 183, 255, .2);
}

.cover-container {
  max-width: 80em;
}


/*
 * Header
 */

.nav-masthead .nav-link {
  padding: .25rem .5rem;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
  text-transform: uppercase;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(0, 183, 255, 1);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: rgba(0, 183, 255, 1);
}

.dev-name {
  text-transform: uppercase;
}

.dev-logo {
  height: 400px;
}

.dev-item-logo {
  height: 330px;
}

.dev-title {
  font-size: 3em;
  font-weight: bold;
  text-align: left;
  line-height: 2.50rem;
}

.dev-title .position {
  color: #00b7ff;
}

.dev-bg {
  background-color: #11100f;
}

.dev-logo-container {
  padding-top: 5%;
}

.dev-text-left {
  text-align: left;
}

.dev-header-text {
  color: #00b7ff;
}

.dev-header-text.line::before {
  content: "";
  display: block;
  width: 10%;
  border-top: 0.5rem solid #00b7ff;
}

.dev-tech-logo {
  height: 60px;
}

